<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else class="content-form">
      <div class="mx-3 content-news-detail p-2" v-html="description"></div>
    </div>

    <div class="text-center w-100 footer">
      <b-button
        @click.prevent="nextToForm"
        class="w-100 btn-gray"
        :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
        >ลงทะเบียนเข้าร่วมกิจกรรม</b-button
      >
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    Breadcrumb,
    OtherLoading
  },
  data() {
    return {
      urlKey: this.$route.query.urlKey,
      description: "",
      breadcrumb: [
        {
          text: "ลงทะเบียนเข้าร่วมกิจกรรม",
          to: "/profile"
        }
      ],
      isLoading: true,
      linkForm: this.$route.query.path
    };
  },
  async created() {
    await this.getCampaignForm();
  },
  methods: {
    async getCampaignForm() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Form/${this.urlKey}`)
        .then(async data => {
          if (data.result == 1) {
            this.description = data.detail.result.description;
          }
          this.isLoading = false;
        });
    },
    nextToForm() {
      window.location.href = this.linkForm + `&guid=${this.$route.query.guid}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-form {
  margin-top: 80px;
  margin-bottom: 4.7rem;
}
.content-news-detail {
  min-height: 100vw;
  background-color: #fff;
  border-radius: 5px;
  word-break: break-all;
}

.btn-gray {
  background: #000;
  border-color: transparent;
  color: #fff;
}
::v-deep p img {
  width: 100%;
}
</style>
